import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CardActionArea from '@mui/material/CardActionArea'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Grid from '@mui/material/Grid'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Snackbar from '@mui/material/Snackbar'
import SvgIcon from '@mui/material/SvgIcon'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import ExpandMoreIcon from '@mui/icons-material/ArrowDropDown'
import ClearIcon from '@mui/icons-material/Clear'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'

import Alert from '@mui/lab/Alert'

import './layouts.css'
import { TheContext } from '../App'

const drawerWidth = 200

const classes = {
  stickyBar: theme => ({
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
    },
    zIndex: theme.zIndex.drawer + 1, // put drawer on bottom of this bar
  }),
  toolbar: theme => ({
    paddingLeft: { xs: theme.spacing(1.8), sm: theme.spacing(1.8) },
  }),
  logoGrid: theme => ({
    paddingRight: { xs: theme.spacing(0), sm: theme.spacing(1) },
    display: 'flex',
    flexDirection: 'row',
    width: { xs: 140, sm: drawerWidth },
  }),
  drawerButtonDesk: {
    display: { xs: 'none', sm: 'block' },
  },
  drawerButtonMobile: {
    display: { xs: 'block', sm: 'none' },
  },
  logo: theme => ({
    marginLeft: { xs: theme.spacing(1), sm: theme.spacing(1) },
    display: 'flex',
    justifyContent: 'flex-end',
    height: theme.spacing(6),
    background: theme.logo.background, // the image uri of log
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    backgroundSize: '96px',
    //backgroundSize: '207px',
    //height: theme.spacing(16),
    //padding: theme.spacing(1, 2, 1, 2),
    // --- break the log image out of its parent's bottom
    //position: 'relative',
    //top: '7px',
  }),

  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchRoot: theme => ({
    zIndex: 0,
    height: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  }),
  searchContainer: theme => ({
    margin: 'auto 2px',
    width: `calc(100% - ${theme.spacing(6 + 4)})`, // 6 button + 4 margin
  }),
  searchInput: theme => ({
    fontSize: theme.typography.fontSize,
    width: '100%',
  }),
  searchClearIconButton: theme => ({
    color: theme.palette.action.active,
    transform: 'scale(1, 1)',
    transition: theme.transitions.create(['transform', 'color'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  }),
  searchClearIconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > icon': {
      opacity: 0,
    },
  },
  searchClearIcon: theme => ({
    fontSize: theme.typography.fontSize,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  }),
  avatarIconButton: {
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  avatarMedium: theme => ({
    width: theme.spacing(4),
    height: theme.spacing(4),
  }),
  searchIconButton: theme => ({
    marginRight: theme.spacing(-6),
  }),
  menuItem: theme => ({
    fontSize: theme.typography.fontSize,
  }),
}

function AddIcon(props) {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path d='M8.8 7.2H5.6V3.9c0-.4-.3-.8-.8-.8s-.7.4-.7.8v3.3H.8c-.4 0-.8.3-.8.8s.3.8.8.8h3.3v3.3c0 .4.3.8.8.8s.8-.3.8-.8V8.7H9c.4 0 .8-.3.8-.8s-.5-.7-1-.7zm15-4.9v-.1h-.1c-.1 0-9.2 1.2-14.4 11.7-3.8 7.6-3.6 9.9-3.3 9.9.3.1 3.4-6.5 6.7-9.2 5.2-1.1 6.6-3.6 6.6-3.6s-1.5.2-2.1.2c-.8 0-1.4-.2-1.7-.3 1.3-1.2 2.4-1.5 3.5-1.7.9-.2 1.8-.4 3-1.2 2.2-1.6 1.9-5.5 1.8-5.7z' />
    </SvgIcon>
  )
}

function Header(props) {
  const { toggleTheme, toggleDeskDrawer, toggleMobileDrawer, deskOpen, mobileOpen } = props
  const { state, dispatch } = React.useContext(TheContext)
  const { pane, by } = useParams()
  const history = useNavigate()

  const [searchText, setSearchText] = React.useState('')
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const [badgeAll, setBadgeAll] = React.useState(0)
  const [badgeOrders, setBadgeOrders] = React.useState(0)
  const [badgeMsgs, setBadgeMsgs] = React.useState(0)

  const [isConfirmOrdersOpen, setIsConfirmOrdersOpen] = React.useState(false)

  const [snackBarText, setSnackBarText] = React.useState()

  const searchInputRef = React.useRef()
  const menuAnchorRef = React.useRef()

  const monitorRef = React.useRef({ numNewMsgs: 0 })

  const handleRequestSearch = () => {
    const theBy = pane === 'search' ? by : 'all'
    history.push(`/search/${searchText}/${theBy}`)
  }

  const handleCancelSearch = () => {
    setSearchText('')
  }

  const handleInput = e => {
    setSearchText(e.target.value)
  }

  const handleBlur = e => {
    setSearchText(v => v.trim())
  }

  const handleKeyUp = ({ key }) => {
    if (key === 'Enter') handleRequestSearch()
    if (key === 'Escape') handleCancelSearch()
  }

  const handleAddBook = () => {
    history.push('/book/0x/add')
  }

  const handleMenuToggle = () => {
    isMenuOpen ? handleMenuClose() : handleMenuOpen()
  }

  const handleMenuOpen = () => {
    setIsMenuOpen(true)
    // back to hover effect
    document.getElementById('header_avatar_button').style.backgroundColor = 'rgba(0, 0, 0, 0.04)'
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
    // force to remove hover effect
    document.getElementById('header_avatar_button').style.backgroundColor = 'inherit'
  }

  const handleMenuItemClick = event => {
    handleMenuClose()
    switch (event.currentTarget.value) {
      case 0:
        history.push(`/user/${state.userId}/list`)
        break
      case 1:
        history.push(`/user/${state.userId}/readings`)
        break
      case 2:
        history.push(`/user/${state.userId}/writings`)
        break
      case 3:
        history.push(`/user/${state.userId}/txs`)
        break
      case 4:
        monitorRef.current.numNewMsgs = 0
        setBadgeMsgs(0)
        setBadgeAll(0 + badgeOrders)
        localStorage.setItem('monitorblock', monitorRef.current.lastBlock)

        history.push(`/user/${state.userId}/inbox`)
        break
      case 5:
        setIsConfirmOrdersOpen(true)
        break
      case 6:
        history.push(`/user/${state.userId}/export`)
        break
      case 7:
        state.account.logout()

        dispatch({
          type: 'LOGOUT',
          account: state.account,
          user: undefined,
          userName: undefined,
          isBookAuthor: false,
          isBookSubscribed: false,
        })

        history.push('/user/0x/signin')
        break
      case 8:
        history.push('/user/0x/signin')
        break
      case 9:
        history.push('/user/0x/import')
        break
      case 10:
        history.push('/')
        break
      case 11:
        toggleTheme()
        break
      default:
    }
  }

  function handleMenuKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setIsMenuOpen(false)
    }
  }

  return (
    <>
      <AppBar
        id='screen_header'
        sx={classes.stickyBar}
        color='default'
        position='sticky'
        elevation={0}
      >
        <Toolbar id='toolbar' sx={classes.toolbar} disableGutters>
          <Grid container spacing={0} alignItems='center'>
            <Grid item sx={classes.logoGrid}>
              <CardActionArea>
                <Link to='/'>
                  <Paper sx={classes.logo} elevation={0} square />
                </Link>
              </CardActionArea>
              <IconButton sx={classes.drawerButtonDesk} onClick={toggleDeskDrawer}>
                {deskOpen ? (
                  <ChevronLeftIcon style={{ display: 'block' }} />
                ) : (
                  <MenuIcon style={{ display: 'block' }} />
                )}
              </IconButton>
              <IconButton sx={classes.drawerButtonMobile} onClick={toggleMobileDrawer}>
                {mobileOpen ? (
                  <ChevronLeftIcon style={{ display: 'block' }} />
                ) : (
                  <MenuIcon style={{ display: 'block' }} />
                )}
              </IconButton>
            </Grid>

            <Grid item>
              <SearchIcon style={{ display: 'block' }} color='inherit' />
            </Grid>

            <Grid item xs>
              <Box id='search-root' sx={classes.searchRoot}>
                <Box id='search-container' sx={classes.searchContainer}>
                  <Input
                    id='search-input'
                    placeholder='Search by Address / Tx Hash / Block / Token / NFT ... '
                    inputRef={searchInputRef}
                    onBlur={handleBlur}
                    value={searchText}
                    onChange={handleInput}
                    onKeyUp={handleKeyUp}
                    fullWidth
                    sx={classes.searchInput}
                    disableUnderline
                  />
                </Box>

                <IconButton
                  onClick={handleCancelSearch}
                  sx={
                    searchText === ''
                      ? classes.searchClearIconButtonHidden
                      : classes.searchClearIconButton
                  }
                >
                  {React.cloneElement(<ClearIcon sx={classes.searchClearIcon} />)}
                </IconButton>
              </Box>
            </Grid>

            <Grid item>
              <IconButton
                id='header_avatar_button'
                ref={menuAnchorRef}
                aria-controls={isMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onMouseEnter={handleMenuOpen}
                onMouseLeave={handleMenuClose}
                onTouchStart={handleMenuToggle}
                color='inherit'
                disableFocusRipple
                disableRipple
                sx={classes.avatarIconButton}
              >
                <Badge badgeContent={badgeAll} color='secondary'>
                  <Avatar
                    src='/images/avatar/khipu32x32.jpg'
                    alt='Khipu'
                    sx={classes.avatarMedium}
                  />
                  <ExpandMoreIcon fontSize='default' />
                </Badge>
              </IconButton>

              <Popper
                open={isMenuOpen}
                anchorEl={menuAnchorRef.current}
                placement='bottom-end'
                role={undefined}
                transition
                disablePortal
                onMouseEnter={handleMenuOpen}
                onMouseLeave={handleMenuClose}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: 'left top',
                    }}
                  >
                    <Paper elevation={3} square>
                      <MenuList id='menu-list-grow' onKeyDown={handleMenuKeyDown}>
                        <MenuItem value={10} onClick={handleMenuItemClick}>
                          <Typography sx={classes.menuItem}>回到主页</Typography>
                        </MenuItem>
                        <MenuItem value={11} onClick={handleMenuItemClick}>
                          <Typography sx={classes.menuItem}>切换主题</Typography>
                        </MenuItem>
                        {!state.account && (
                          <MenuItem value={9} onClick={handleMenuItemClick}>
                            <Typography sx={classes.menuItem}>导入帐号</Typography>
                          </MenuItem>
                        )}
                        {state.account && state.account.isTrivialSaved() && (
                          <MenuItem value={0} onClick={handleMenuItemClick}>
                            <Typography sx={classes.menuItem}>我的帐号</Typography>
                          </MenuItem>
                        )}
                        {state.account && state.account.isTrivialSaved() && (
                          <MenuItem value={1} onClick={handleMenuItemClick}>
                            <Typography sx={classes.menuItem}>我的订阅</Typography>
                          </MenuItem>
                        )}
                        {state.account && state.account.isTrivialSaved() && (
                          <MenuItem value={2} onClick={handleMenuItemClick}>
                            <Typography sx={classes.menuItem}>我的作品</Typography>
                          </MenuItem>
                        )}
                        {state.account && state.account.isTrivialSaved() && (
                          <MenuItem value={3} onClick={handleMenuItemClick}>
                            <Typography sx={classes.menuItem}>我的交易</Typography>
                          </MenuItem>
                        )}
                        {state.account && state.account.isTrivialSaved() && (
                          <MenuItem value={4} onClick={handleMenuItemClick}>
                            <Badge badgeContent={badgeMsgs} color='secondary'>
                              <Typography sx={classes.menuItem}>我的留言</Typography>
                            </Badge>
                          </MenuItem>
                        )}
                        {state.account && state.account.isTrivialSaved() && badgeOrders > 0 && (
                          <MenuItem value={5} onClick={handleMenuItemClick}>
                            <Badge badgeContent={badgeOrders} color='secondary'>
                              <Typography sx={classes.menuItem}>确认订单</Typography>
                            </Badge>
                          </MenuItem>
                        )}
                        {state.account && state.account.isTrivialSaved() && (
                          <MenuItem value={6} onClick={handleMenuItemClick}>
                            <Typography sx={classes.menuItem}>导出密钥</Typography>
                          </MenuItem>
                        )}
                        {state.account && state.account.isTrivialSaved() && (
                          <MenuItem value={7} onClick={handleMenuItemClick}>
                            <Typography sx={classes.menuItem}>退出登录</Typography>
                          </MenuItem>
                        )}
                        {state.account && !state.account.isTrivialSaved() && (
                          <MenuItem value={8} onClick={handleMenuItemClick}>
                            <Typography sx={classes.menuItem}>登录</Typography>
                          </MenuItem>
                        )}
                      </MenuList>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
