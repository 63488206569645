import React from 'react'
import * as d3 from 'd3'
import techan from 'techan'

import { innerDimensions } from './charts'
import './charts.css'

const json = [
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:19:30.783',
    bid: 0.72219,
    ask: 0.72235,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:04.638',
    bid: 0.72215,
    ask: 0.72232,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:04.744',
    bid: 0.72212,
    ask: 0.72228,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:04.966',
    bid: 0.72208,
    ask: 0.72225,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:38.373',
    bid: 0.72205,
    ask: 0.72221,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:38.552',
    bid: 0.72201,
    ask: 0.72218,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:38.663',
    bid: 0.72197,
    ask: 0.72215,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:49.062',
    bid: 0.72201,
    ask: 0.72219,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:49.605',
    bid: 0.72207,
    ask: 0.72223,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:51.992',
    bid: 0.72204,
    ask: 0.7222,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:52.140',
    bid: 0.722,
    ask: 0.72217,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:53.568',
    bid: 0.72205,
    ask: 0.7222,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:53.803',
    bid: 0.72208,
    ask: 0.72226,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:54.123',
    bid: 0.72213,
    ask: 0.7223,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:21:54.337',
    bid: 0.72218,
    ask: 0.72237,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:10.125',
    bid: 0.72222,
    ask: 0.7224,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:10.270',
    bid: 0.72225,
    ask: 0.72244,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:21.045',
    bid: 0.7223,
    ask: 0.72247,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:35.919',
    bid: 0.72235,
    ask: 0.72252,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:36.010',
    bid: 0.72239,
    ask: 0.7226,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:36.100',
    bid: 0.72245,
    ask: 0.72265,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:37.489',
    bid: 0.72245,
    ask: 0.72262,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:37.577',
    bid: 0.72243,
    ask: 0.72259,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:37.812',
    bid: 0.7224,
    ask: 0.72255,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:55.599',
    bid: 0.72243,
    ask: 0.72261,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:22:55.895',
    bid: 0.72248,
    ask: 0.72266,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:08.161',
    bid: 0.72253,
    ask: 0.72269,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:08.462',
    bid: 0.72257,
    ask: 0.72275,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:09.546',
    bid: 0.72254,
    ask: 0.72272,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:09.643',
    bid: 0.7225,
    ask: 0.72269,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:22.294',
    bid: 0.72247,
    ask: 0.72266,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:22.492',
    bid: 0.72244,
    ask: 0.72262,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:29.317',
    bid: 0.7225,
    ask: 0.72266,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:29.580',
    bid: 0.72253,
    ask: 0.72272,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:33.935',
    bid: 0.72251,
    ask: 0.72268,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:35.001',
    bid: 0.72248,
    ask: 0.72264,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:23:44.167',
    bid: 0.72244,
    ask: 0.72261,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:25:43.541',
    bid: 0.72248,
    ask: 0.72264,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:27:41.336',
    bid: 0.72251,
    ask: 0.72268,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:27:41.505',
    bid: 0.72254,
    ask: 0.72272,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:01.442',
    bid: 0.7225,
    ask: 0.72267,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:08.178',
    bid: 0.72255,
    ask: 0.72272,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:12.547',
    bid: 0.72259,
    ask: 0.72277,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:12.636',
    bid: 0.72264,
    ask: 0.72283,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:12.835',
    bid: 0.72269,
    ask: 0.72287,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:15.798',
    bid: 0.72266,
    ask: 0.72283,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:15.886',
    bid: 0.72262,
    ask: 0.7228,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:16.063',
    bid: 0.72259,
    ask: 0.72276,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:18.578',
    bid: 0.72264,
    ask: 0.72281,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:18.832',
    bid: 0.72268,
    ask: 0.72287,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:19.795',
    bid: 0.72273,
    ask: 0.7229,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:24.464',
    bid: 0.7227,
    ask: 0.72286,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:38.955',
    bid: 0.72274,
    ask: 0.72291,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:52.644',
    bid: 0.72271,
    ask: 0.72288,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:28:53.545',
    bid: 0.72267,
    ask: 0.72285,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:29:46.713',
    bid: 0.72264,
    ask: 0.72282,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:29:46.796',
    bid: 0.72261,
    ask: 0.72278,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:29:47.070',
    bid: 0.72258,
    ask: 0.72274,
  },
  {
    provider: 'abroker',
    instrument: 'AUD_USD',
    time: '2015-09-18T17:29:48.488',
    bid: 0.72263,
    ask: 0.72279,
  },
]

function TechChart({ height, containerId }) {
  const ref = React.useRef()

  React.useEffect(() => {
    chart(height)
    const handleResize = () => {
      chart(height)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const chart = fullHeight => {
    const container = document.getElementById(containerId)
    const fullWidth = innerDimensions(container).width

    const svg = d3.select(ref.current).attr('width', fullWidth).attr('height', fullHeight)

    //svg.selectAll('*').remove() // TODO let TechChart be pure function

    var margin = { top: 20, right: 20, bottom: 100, left: 55 },
      margin2 = { top: 420, right: 20, bottom: 20, left: 55 },
      width = fullWidth - margin.left - margin.right,
      height = fullHeight - margin.top - margin.bottom,
      height2 = fullHeight - margin2.top - margin2.bottom

    var tickDateTimeParser = d3.timeParse('%Y-%m-%dT%H:%M:%S.%L')

    var x = techan.scale.financetime().range([0, width])

    var x2 = techan.scale.financetime().range([0, width])

    var y = d3.scaleLinear().range([height, 0])

    var yVolume = d3.scaleLinear().range([y(0), y(0.3)])

    var y2 = d3.scaleLinear().range([height2, 0])

    var brush = d3
      .brushX()
      .extent([
        [0, 0],
        [width, height2],
      ])
      .on('end', brushed)

    var tick = techan.plot.tick().xScale(x).yScale(y)

    // FIXME: change volume to spead chart
    var volume = techan.plot.volume().xScale(x).yScale(yVolume)

    // FIXME: change close to line chart
    var close = techan.plot.close().xScale(x2).yScale(y2)

    var xAxis = d3.axisBottom(x)

    var xAxis2 = d3.axisBottom(x2)

    var yAxis = d3.axisLeft(y)

    var yAxis2 = d3.axisLeft(y2).ticks(0)

    var tickAnnotation = techan.plot
      .axisannotation()
      .axis(yAxis)
      .orient('left')
      .format(d3.format(',.5f'))

    var timeAnnotation = techan.plot
      .axisannotation()
      .axis(xAxis)
      .orient('bottom')
      .format(d3.timeFormat('%H:%M:%S.%L'))
      .width(70)
      .translate([0, height])

    var crosshair = techan.plot
      .crosshair()
      .xScale(x)
      .yScale(y)
      .xAnnotation(timeAnnotation)
      .yAnnotation(tickAnnotation)

    //var svg = d3.select("body").append("svg")
    //        .attr("width", width + margin.left + margin.right)
    //        .attr("height", height + margin.top + margin.bottom);

    const focus = svg
      .select('g.focus')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    focus
      .select('clipPath')
      .select('rect')
      .attr('x', 0)
      .attr('y', y(1))
      .attr('width', width)
      .attr('height', y(0) - y(1))

    focus.select('g.spread').attr('clip-path', 'url(#clip)')
    focus.select('g.tick').attr('clip-path', 'url(#clip)')

    focus.select('g.x.axis').attr('transform', 'translate(0,' + height + ')')
    focus
      .select('g.y.axis')
      .select('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '.71em')
      .style('text-anchor', 'end')
      .text('Price ($)')

    focus.select('g.crosshair').call(crosshair)

    focus
      .select('text.symbol')
      .attr('x', 6)
      .attr('y', -8)
      .text('USDC')
      .style('cursor', 'pointer')
      .on('click', function (d) {
        alert(d)
      })

    const context = svg
      .select('g.context')
      .attr('transform', 'translate(' + margin2.left + ',' + margin2.top + ')')

    context.select('g.x.axis').attr('transform', 'translate(0,' + height2 + ')')
    context.select('g.y.axis').call(yAxis2)

    var accessor = tick.accessor()
    var data = json.map(function (d, index, array) {
      return {
        date: tickDateTimeParser(d.time),
        high: +d.ask,
        low: +d.bid,
        spread: (d.ask - d.bid) / 0.0001,
        volume: (d.ask - d.bid) / 0.0001,
        open: +d.ask,
        close: +d.bid,
      }
    })

    data.sort(function (a, b) {
      return d3.ascending(accessor.d(a), accessor.d(b))
    })

    x.domain(data.map(accessor.d))
    x2.domain(x.domain())
    y.domain(techan.scale.plot.ohlc(data, accessor).domain())
    y2.domain(y.domain())
    yVolume.domain(techan.scale.plot.volume(data).domain())

    focus.select('g.tick').datum(data)
    focus.select('g.spread').datum(data)

    context.select('g.close').datum(data).call(close)
    context.select('g.x.axis').call(xAxis2)

    // Associate the brush with the scale and render the brush only AFTER a domain has been applied
    context.select('g.pane').call(brush).selectAll('rect').attr('height', height2)

    x.zoomable().domain(x2.zoomable().domain())
    draw()

    function brushed(event) {
      const zoomable = x.zoomable()
      const zoomable2 = x2.zoomable()

      zoomable.domain(zoomable2.domain())
      const selection = event.selection
      if (selection !== null) {
        zoomable.domain(selection.map(zoomable.invert))
      }
      draw()
    }

    function draw() {
      var tickSelection = focus.select('g.tick')
      var data = tickSelection.datum()
      y.domain(
        techan.scale.plot
          .ohlc(data.slice.apply(data, x.zoomable().domain()), tick.accessor())
          .domain()
      )
      tickSelection.call(tick)
      focus.select('g.spread').call(volume)
      focus.select('g.x.axis').call(xAxis)
      focus.select('g.y.axis').call(yAxis)
    }
  }

  return (
    <svg ref={ref}>
      <g className='focus'>
        <text className='symbol' />
        <clipPath id='clip'>
          <rect />
        </clipPath>
        <g className='spread' />
        <g className='tick' />
        <g className='x axis' />
        <g className='y axis'>
          <text />
        </g>
        <g className='crosshair' />
      </g>

      <g className='context'>
        <g className='close' />
        <g className='pane' />
        <g className='x axis' />
        <g className='y axis' />
      </g>
    </svg>
  )
}

export default TechChart
