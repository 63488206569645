import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const classes = {
  footer: {
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f5f5f5',
  },
}

function Footer(props) {
  return (
    <Box sx={classes.footer}>
      <Typography variant='body2' color='textSecondary' align='center'>
        © {new Date().getFullYear()} Infini Matrix. All rights reserved.
      </Typography>
    </Box>
  )
}

export default Footer
