import React from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardActionArea from '@mui/material/CardActionArea'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FreeIcon from '@mui/icons-material/FreeBreakfast'
import InputIcon from '@mui/icons-material/Input'
import OutputIcon from '@mui/icons-material/Output'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import './layouts.css'
import { TheContext } from '../App'
import { ListItemButton } from '@mui/material'

const queryString = require('query-string')

//const KhipuService = require('../services/KhipuService')

const classes = {
  stickyBar: {
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
  },
  logo: theme => ({
    display: 'flex',
    justifyContent: 'flex-end',
    height: theme.spacing(6),
    marginLeft: theme.spacing(2),
    background: theme.logo.background, // the image uri of log
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    backgroundSize: '96px',
    //backgroundSize: '207px',
    //height: theme.spacing(16),
    //padding: theme.spacing(1, 2, 1, 2),
    // --- break the log image out of its parent's bottom
    position: 'relative',
    //top: '7px',
  }),
  categoryHeader: theme => ({
    //paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(1),
    '& .MuiListItemText-primary': {
      //color: theme.palette.common.white,
    },
  }),
  item: {
    //pl: 3,
    //paddingTop: 1,
    //paddingBottom: 1,
    //color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      //backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActive: theme => ({
    //pl: 0,
    color: theme.palette.common.white,
    background: theme.palette.info.main,
    '&:hover,&:focus': {
      background: theme.palette.info.main,
    },
    //'& .MuiListItemText-primary': {
    //  fontSize: 'inherit',
    //},
    //color: theme.palette.primary.main,
  }),
  itemIcon: theme => ({
    minWidth: 'auto',
    marginRight: theme.spacing(1),
  }),
  itemIconActive: theme => ({
    minWidth: 'auto',
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  }),
  link: {
    textDecoration: 'none',
    userSelect: 'none',
    color: 'inherit',
    '&:hover,&:focus,&:visited': {
      color: 'inherit',
    },
  },
  categoryIPrimary: {
    fontSize: 20,
    // variant: 'h5',
    fontWeight: 'bold',
    // letterSpacing: 0,
  },
  categoryIIPrimary: {
    fontSize: 16,
    fontWeight: 'Bold',
    lineHeight: '8px',
    // letterSpacing: 0,
  },
  itemPrimary: {
    fontSize: 13,
    // fontWeight: 'medium',
    lineHeight: '0px',
    mb: '2px',
  },
}

const watchListTokens = [
  ['USDC', '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'],
  ['USDT', '0xdac17f958d2ee523a2206206994597c13d831ec7'],
  ['WETH', '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'],
  ['WBTC', '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'],
  ['DAI', '0x6b175474e89094c44da98b954eedeac495271d0f'],
  ['HEX', '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39'],
]

function Navigator(props) {
  const { closeMobileDrawer, closeDeskDrawer } = props
  const { state, dispatch } = React.useContext(TheContext)
  const { what, pane, address0, address1 } = useParams()
  const queries = queryString.parse(window.location.search)
  const f = queries.f

  const navigate = useNavigate()

  const PAGE_SIZE = 30

  const [tokens, setTokens] = React.useState([])
  const [userName, setUserName] = React.useState(undefined)
  const [chapters, setChapters] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)

  const [page, setPage] = React.useState(1)
  const [numPages, setNumPages] = React.useState(1)

  const [isFetching, setIsFetching] = React.useState(false)

  React.useLayoutEffect(() => {
    document.getElementById('navigator').scrollTop = 0
  })

  React.useEffect(() => {
    setTokens(state.data.map(json => ({ symbol: json.symbol, address: json.address })))
    // console.log(state.data.length, state.dataChanged)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataChanged])

  const handleNavigateToAddress0 = addr => {
    closeMobileDrawer()

    if (f) {
      navigate(`/${what}/${pane}/${addr}?f=${f}`)
    } else {
      navigate(`/${what}/${pane}/${addr}`)
    }
  }

  const handleNavigateToAddress1 = addr => {
    closeMobileDrawer()

    if (f) {
      navigate(`/${what}/${pane}/${address0}/${addr}?f=${f}`)
    } else {
      navigate(`/${what}/${pane}/${address0}/${addr}`)
    }
  }

  return (
    <Box id='navigator' className='none_scrollbar'>
      <AppBar
        id='navigator_sticky'
        sx={classes.stickyBar}
        color='default'
        position='sticky'
        elevation={0}
      ></AppBar>

      {what === 'token' ? (
        <List id='navigator_scrollable' disablePadding>
          <ListItem key='bookCategory' sx={classes.categoryHeader}>
            <ListItemText primaryTypographyProps={classes.categoryIPrimary}>Watchlist</ListItemText>
          </ListItem>
          <Divider />
          {watchListTokens.map(([symbol, address], i) => (
            <>
              <ListItemButton
                key='i'
                sx={address === address0 ? classes.itemActive : classes.item}
                onClick={() => {
                  if (address === address0) {
                    dispatch({ cataIIOpen: !state.cataIIOpen })
                  } else {
                    dispatch({ cataIIOpen: true })
                    handleNavigateToAddress0(address)
                  }
                }}
              >
                <ListItemText primaryTypographyProps={classes.categoryIIPrimary}>
                  {symbol ? '= ' + symbol : '= ' + address.substring(0, 10) + '...'}
                </ListItemText>
                {address === address0 && (state.cataIIOpen ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {address === address0 && (
                <Collapse in={state.cataIIOpen} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {tokens.map(({ symbol, address }, i) => (
                      // <Link to={`/token/price/${address0}/${address}`} key={i} style={classes.link}>
                      <ListItemButton
                        key={i}
                        sx={address === address1 ? classes.itemActive : classes.item}
                        onClick={() => handleNavigateToAddress1(address)}
                      >
                        {/* <ListItemIcon sx={address === address1 ? classes.itemIconActive : classes.itemIcon}>
                   <ExitToAppIcon />
                 </ListItemIcon> */}
                        <ListItemText primaryTypographyProps={classes.itemPrimary}>
                          {symbol ? '- ' + symbol : '- ' + address.substring(0, 10) + '...'}
                        </ListItemText>
                      </ListItemButton>
                      // </Link>
                    ))}
                  </List>
                </Collapse>
              )}
            </>
          ))}
        </List>
      ) : (
        <div></div>
      )}
    </Box>
  )
}

export default Navigator
